import APP from "./APP";
import { apiPortal, env } from "../config/env";
import axios from "axios";
import qs from "qs";
import { Toast } from "vant";
import { desCode } from "./encryption";

const API = process.env.VUE_APP_API;
console.log(API,999)
// 添加请求拦截器，提交的时候加密
axios.interceptors.request.use(
  async function(config) {
    Toast.loading({
      message: "加载中...",
      forbidClick: true
    });
    await APP.DEVICE().then(res => {
      if (res) {
        let headerRd = encodeURIComponent(JSON.stringify(res));
        config.headers.device = headerRd;
        // console.log(res,'device')
        window.localStorage.setItem('appName',res.appName)
        const tokenUser = window.localStorage.getItem("tokenID");
        if (tokenUser) {
          // console.log(tokenUser,'token')
          config.headers.Authorization = tokenUser;
        }
      }
    });
    
    //本地调试
    // const rd = {
    //   appName :'SYX' ,
    //   appSourceId :47 ,
    //   appVersion :"1.0.0",
    //   availableStorage : "2694.12 MB",
    //   carrierOperator : "\U4e2d\U56fd\U8054\U901a",
    //   clientCity : "\U676d\U5dde\U5e02",
    //   clientIp : "192.168.30.12",
    //   clientProvince : "\U6d59\U6c5f\U7701",
    //   clientRegion :"\U4e09\U575d\U5df7, \U676d\U5dde\U5e02, \U897f\U6e56\U533a, \U6d59\U6c5f\U7701, \U4e2d\U56fd",
    //   device : 'ios',
    //   deviceId :'cc4140c6f5d8b7cbcd46c43f84776cf1',
    //   deviceName : "\U5415\U7e41\U521a\U7684iPhone",
    //   electricQuantity :"100.00%",
    //   idfa :"AA3DBAAE-53A7-4593-AFAE-DB642D28EEAD",
    //   lat : "30.302970",
    //   lng: "120.095028",
    //   mac : "02:00:00:00:00:00",
    //   mobileType : "iPhone 6s",
    //   networkStatus : 'Wifi',
    //   osVersion: "14.2",
    //   pushId : "",
    //   resolution :"750*1334",
    //   screenHeight : 667,
    //   screenWidth :375,
    //   tId:"eyJ0b2tlbklkIjoiQk9vMTlveWxZajduVEpLbXpHekRSSzlHYUdFV1Z5YWZ6Tm84ZVR3aDNmUjNObFJ6bjRURlBiZFN6UFF5UlZYa3FZd1RJazdFYWxIMjE2ZzJUUm5Da0E9PSIsIm9zIjoiaU9TIiwic2VxSWQiOiIxNjM2NDI0MDUwMDEwMzI5MDA2IiwicHJvZmlsZVRpbWUiOjQ2MiwidmVyc2lvbiI6IjMuNi4xIn0=",
      
    //   totalStorage :"15238.75 MB",
    //   wifiMac : "48:bd:3d:66:af:b0",
    // }
    // let headerRd = encodeURIComponent(JSON.stringify(rd));
    // config.headers.device = headerRd;
    // window.localStorage.setItem('appName','SYX')
    // config.headers.Authorization = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VySWQiOiJVOTEwMTM3OTUyNzk1ODI0NTIxMjE2IiwiaWF0IjoxNjYyMDg3NTQyLCJleHAiOjE2NjQ2Nzk1NDJ9.I3bNTLxlJwboGSqqc_qFcx9bglk90nr0ZONEEMvxqTg';
    
   


    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// 添加返回拦截，返回的时候解密
axios.interceptors.response.use(
  function(response) {
    Toast.clear();
    // 对响应数据做点什么
    return response;
  },
  function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

/* Portal   网络请求*/
const Fetch = async options => {
  let fetch;
  let { url, data, env, method } = options;
  let baseUrl = `${API}/${url}`;
  // console.log(tokenUser,888)
  // console.log(process.env, "huan jing bian liang");
  //线上加密
  
  // if (process.env.NODE_ENV === "production") {
  //   try{
  //     data = desCode.ncrypted(data);
  //     console.log(data,9994)
  //   }catch(err){
  //     console.log(err,9996)
  //   }
  //  }

  switch (method.toLowerCase()) {
    case "get":
      fetch = axios.get(baseUrl, {
        params: data,
        withCredentials: false       
      });

      break;
    case "post":
      fetch = axios.post(baseUrl, data, {
        transformRequest: [
          function(data) {
            data = JSON.stringify(data);
            return data;
          }
        ],
        // 表示跨域请求时是否需要使用凭证 默认false
        withCredentials: false,
        //设置header
        headers: {
          Accept: "application/json;charset=UTF-8",
          "Content-Type": "application/json;charset=UTF-8"        
        }
      });

      break;
    default:
      fetch = axios({ ...options });
      break;
  }

  return fetch;
};

// 对外暴露的方法
const request = function(options) {  
  return Fetch(options)
    .then(res => {
      console.log(res, "fan hui jie guo");
      if (res.status === 200 && res.data.success) {
        const result = res.data.result;
        // console.log(result, 888885);
        //解密
        // if (process.env.NODE_ENV === "production") {
        //   result = desCode.decrypted(...result);
        // }
        return Promise.resolve({ ...result });
      } else {
        const { result, message } = res.data;
        // console.log(result,888884)
        Toast({ message, position: "top" });
        throw new Error({ ...result });
      }
    })
    .catch(error => {
      const { response } = error;
      let msg;
      let statusCode;
      if (response && response instanceof Object) {
        const { data, statusText } = response;
        statusCode = response.status;
        msg = data.message || statusText;
      } else {
        statusCode = 600;
        msg = error.message || "服务器开小差了...";
        if (error.message === "Network Error") {
          msg = "服务器开小差了";
        }
      }

      /* eslint-disable */
      return Promise.reject({
        succ: false,
        statusCode,
        message: msg
      });
    });
};

export default {
  // get方法封装
  get: function(url, data, env) {
    return request({
      url,
      env,
      data: data ? data : "",
      method: "GET"
    });
  },
  // post方法封装
  post: function(url, data, env) {
    return request({
      url,
      env,
      data: data ? data : "",
      method: "POST"
    });
  }
};
