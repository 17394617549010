<template>
  <div class='i-p-header'>
      <template v-if="list.length>0">
        <div class="i-header-content" >
          <!--卡片-->
          <div class="i-p-card" v-for="(item) in list" :key="item.id">
            <div class="i-card-top">
              <div class="i-card-left">
                <img :src='item.appLogo' class="i-card-img" alt='产品图' />
                <div class='i-card-text-l'>{{item.appName}}</div>
              </div>
              <!-- 借款时间 -->
              <div class="i-card-center">借款日：{{item.gmtCreated}}</div>
              <div class="i-card-right">还款中</div>
            </div>
            <!-- 金额和提醒 -->
            <div class="i-card-middle">
              <div class="i-card-middle-left">
                {{item.amount}}
              </div>
              <div class="i-card-middle-right" v-if="item.status=='CURRENT'">
                <span class="i-card-middle-right-date">待还款</span>
              </div>
              <div class="i-card-middle-right" v-if="item.status!='OVERDUED' && item.status!='CURRENT' ">
                <span class="i-card-middle-right-date">未到期</span>
              </div>
              <div class="i-card-middle-right" v-if="item.status=='OVERDUED'">
                <span class="i-card-middle-right-date-radu">已逾期</span>
              </div>
            </div>
            <div class="i-card-bottom">
              <div class="i-card-bottom-left">
                本期账单
              </div>
              <div class="i-card-bottom-right">
                {{item.currentDate}}
              </div>
              <div class="i-card-middle-button">
                <div class="i-card-middle-button-text" @click="goBackurl(item)">立即还款</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="i-content-login">
          <div class="i-login-img"/>
          <p class="i-login-desc">{{ desc }}</p>
          <div @click="GoLoan" class="i-login-btn">
            去借款
          </div>
        </div>
      </template>

  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import API from '../../server/api'
import APP from '../../utils/APP'
import {routerTag } from '../../utils/index'
import Moment from 'moment';
export default {
  name: 'Bill',
  setup () {

    // 定义当前页面的state
    const state = reactive({
      // isLogin: false,
      list: [],
      hasNextPage: false,
      desc: '目前还没有账单哦，去借一笔',
      // routerUrl: 'http://192.168.0.110:8080/index.html#/loan',
     routerUrl:'https://m.rockstect.com/#/loan'

    })
    // APP.Alert(`${routerTag}${loan}`)

    // 初始化数据
    const init = async () => {

      const result = await API.bill({})
      console.log(result,999);
      if(result.billList){
        state.list = result.billList

      }
      //  console.log(  state.list,997);
    }

    // 页面加载
    onMounted(() => {
      window.TDAPP.onEvent("账单页-进入");
      APP.SET_TITLE('账单')
      init()
    })

    // 去借款
    const GoLoan = () => {
   
      APP.setData(JSON.stringify(1))
      APP.JUMP_H5(routerTag)
    }
    //立即还款
    const goBackurl=(item)=>{
      window.localStorage.setItem('loanNo',item.loanNo)
      APP.JUMP(item.url, item.appName)
    }
    return {
      ...toRefs(state),
      GoLoan,
      // GoLogin,
      goBackurl
    }
  },
}
</script>

<style scoped lang='less' src='./index.less'></style>
