<template>
  <div class="i-page">
    <!--  Header-->
    <!-- 消息通知 :class='checked==true ? "pro-icon" : "proIcon-active"'-->
    <div v-if="notions.title">
      <div class="i-header-top" @click="goMent(notions.url)">
        <div class="i-top-icon"></div>
        <div class="i-top-text">{{ notions.title }}</div>
      </div>
    </div>

    <div :class='notions.title ? "i-header-index" :"i-header-in"'>
      <div class="i-header-cont">
        <!--组件-->
        <template v-if="data.cardIndex && data.cardIndex.length > 0">
          <quota-card
            v-bind:step="data.process"
            v-bind:data="data.cardIndex[0]"
          />
        </template>
      </div>
    </div>
    <div class="i-card-main">
      <div class="i-card-contents">
        <div
          class="i-card-f"
          @click="goRecord(item)"
          v-for="item in bannerList"
          :key="item.targetId"
        >
          <img class="i-card-f-img" :src="item.imgUrl" alt="999" />
          <div class="i-card-f-text">{{ item.targetType }}</div>
        </div>
      </div>
    </div>
    <!--  推荐产品-->
    <div class="i-recommend" v-if="advList">
      <h2>推荐产品</h2>
      <div class="i-r-content">
        <div
          class="i-r-item"
          v-for="item in advList"
          :key="item.targetId"
          @click="goAdv(item.targetUrl)"
        >
          <img :src="item.imgUrl" alt="数码" />
        </div>
      </div>
    </div>
    <div class="i-hot-product" id="is-fiexd" name="is-fiexd">
      <div class="i-hot-header">
        <!-- <van-sticky :offset-top="50" > -->
        <h2 class="i-hot-right">热门产品</h2>
        <!-- </van-sticky> -->
      </div>

      <!-- 卡片 -->
      <div
        class="i-hot-card"
        v-for="item in proList"
        :key="item.id"
        @click="proCard(item)"
      >
        <div class="i-hot-card-header">
          <div class="i-hot-card-img">
            <img :src="item.prodIcon" alt="产品图" />
          </div>
          <div class="i-hot-card-text">{{ item.prodName }}</div>
        </div>
        <div class="i-hot-card-moeny">
          <div class="i-hot-card-number">{{ item.totalCreditAmt }}</div>
          <div class="i-hot-card-btn ">{{ item.button.label }}</div>
        </div>
        <div class="i-hot-card-des">
          <div class="i-hot-card-foo" v-if="item.slogans.length == 1">
            {{ item.slogans[0] }}
          </div>
          <div class="i-hot-card-footer" v-else>
            <div class="i-hot-footer-right">
              {{ item.slogans[0] }}
            </div>
            <div class="i-hot-footer-left">
              {{ item.slogans[1] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class='test-btn' @click="testBF">
       拨打电话
      </div> -->
  </div>
</template>

<script>
// 首页额度
import QuotaCard from "@/components/QuotaCard/index";
import API from "../../server/api.js";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.less";
import { onMounted, reactive, toRefs } from "vue";
import APP from "../../utils/APP";
import { routerTag } from "../../utils/index";
import { Toast } from "vant";
export default {
  name: "Index",
  components: {
    Swiper,
    SwiperSlide,
    "quota-card": QuotaCard
  },

  setup() {
    const state = reactive({
      data: {},
      cardIndex: [], //卡片
      bannerList: [],
      advList: [],
      notions: "", //公告
      proList: [], //热门
      creditState: null //授信状态
      // isFiexd:false, //定位到顶部
      // cell:''
    });
    // const router = useRouter() //2、使用 路由跳转
    //登录
    const init = async () => {
      //creditState  授信状态 0 未授信
      window.TDAPP.onEvent("首页-进入");
      const result = await API.index({});
      state.data = result;
      state.bannerList = result.bannerList;
      state.notions = result.notifies;
      state.proList = result.productList;
      state.advList = result.adList;
      // console.log(result.notifies,98);
    };

    //系统公告
    const goMent = item => {
      window.localStorage.setItem(
        "notions",
        JSON.stringify(state.notions.context)
      );
      // console.log(5433);
      const singleUrl = routerTag + "announcement";
      APP.JUMP_H5(singleUrl, "系统公告");
    };
    //借款
    const goRecord = item => {
      APP.JUMP_H5(item.targetUrl);
    };

    //热门卡片按钮
    const goCredit = item => {
      
      const { url, clickable } = item.button;
      const { process } = state.data;
      const step = process;
      console.log(step,66)
      if (step == 0) {
        const authUrl = routerTag + "auth";
        APP.JUMP_H5(authUrl, "实名认证");
      } else if (step == 1) {
        const faceUrl = routerTag + "authFace";
        APP.JUMP_H5(faceUrl, "人脸识别");
      } else if (step == 2) {
        const baseUrl = routerTag + "baseInfo";
        APP.JUMP_H5(baseUrl, "基础信息");
      } else {
        APP.JUMP_H5(url);
      }
    };
    //卡片整体
    const proCard = async item => {
      // console.log(JSON.stringify(item), 88);
      window.TDAPP.onEvent("热门卡片点击-进入");
      window.localStorage.setItem("appNo", item.appNo);
      //存数据
      APP.setData("appNo", item.appNo);
      CreditDes(item);
    };
    const CreditDes = async item => {
      const result = await API.indexDes({});
      window.localStorage.setItem("orderNo", result.creditResult.orderNo);
      if (result.creditResult.creditStatus == 4) {
        const loanUrl = routerTag + "loan";
        APP.JUMP_H5(loanUrl, "借款");
      } else if (result.creditResult.creditStatus == 7) {
        window.localStorage.setItem("loanNo", result.creditResult.loanNo);
        const billUrl = routerTag + "BillDetail";
        APP.JUMP_H5(billUrl, "账单详情");
      } else {
        initCard(item);
      }
    };
    const initCard = item => {
      //  window.localStorage.setItem('appNo',item.appNo)
      //判断是否授信 creditState  0 未授信    1已授信
      state.creditState = item.creditState;
      //产品是h5还是api对接type   0 api    1 h5
      const type = item.type;
      if (type == 0) {
        if (state.creditState == 0) {
          goCredit(item);
        }
      } else {
        //跳转机构的给的url地址   h5产品过度页然后显示h5的画url地址显示
        const mechanUrl = routerTag + "mechanism";
        APP.setData('apiDate',JSON.stringify(item))
        window.TDAPP.onEvent(item.prodName);
        APP.JUMP_H5(mechanUrl, "正在前往第三方机构");
      }
    };
    const goAdv = item => {
      APP.JUMP_H5(item);
    };
    //滑动到具体位置
    const ScorllGet = () => {
      APP.getData(function(res) {
        if (res == 1 && res != null) {
          setTimeout(() => {
            document.documentElement.scrollTop = document.getElementById(
              "is-fiexd"
            ).offsetTop;
            // window.scrollBy(0,600)
          }, 1000);
        }
      });
    };
  
    onMounted(() => {
      
      ScorllGet();
      // APP.IS_SHOW_BACK(false);//隐藏返回按钮


    //本地联调完毕打开
      APP.mobile_info(mobile => {
        if (mobile) {
        
          API.login({ mobile }).then(result => {
           
            window.localStorage.setItem("tokenID", result.token);
            init();
          });
        }
      });

      // init();//本地联调


      APP.PAGE_WILL_LOAD(() => {
       
        APP.REFRESH();
      });
    });

    return {
      ...toRefs(state),
      init,
      goRecord,
      goMent,
      // scorllGet,
      goCredit,
      proCard,
      goAdv
      // testBF
    };
  }
};
</script>

<style lang="less" src="./index.less" scoped></style>
