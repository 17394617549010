// 格式化金额，数字
const formatMoney = function (s, n) {
  console.log('formatMoney')
}
// 获取hash路由URL的参数
const GetUrlParams = function (href) {

  const u = href ? href : window.location.href;
  const o = {}
  const startNum = u.indexOf('?')
  if (startNum !== -1) {
    const paramsStr = u.substring(startNum+1);
    if(paramsStr.indexOf('&')!==-1){
      let cacheArr = paramsStr.split('&');
      cacheArr.forEach((item)=>{
        let arr = item.split('=');
        o[arr[0]] = arr[1];
      })
    }
    else{
      let paramsArr = paramsStr.split('=');
      o[paramsArr[0]] = paramsArr[1];
    }
  }
  return o;
}

// 判断对象是否为空
function isEmptyObj(e) {
  let t
  for (t in e){
    return !1;
  }
  return !0
}
//转化日期
Date.prototype.format = function(fmt) {
  var o = {
     "M+" : this.getMonth()+1,                 //月份
     "d+" : this.getDate(),                    //日
     "h+" : this.getHours(),                   //小时
     "m+" : this.getMinutes(),                 //分
     "s+" : this.getSeconds(),                 //秒
     "q+" : Math.floor((this.getMonth()+3)/3), //季度
     "S"  : this.getMilliseconds()             //毫秒
 };
 if(/(y+)/.test(fmt)) {
         fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
 }
  for(var k in o) {
     if(new RegExp("("+ k +")").test(fmt)){
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
      }
  }
 return fmt;
}
const timeDate=Date.prototype.format
//跳转的公共路径
// const routerTag='http://m.jqtianxia.cn/mc/index.html#/'
// const routerTag='http://192.168.0.78:8086/#/'
  // const routerTag='http://supertest.jqtianxia.cn/#/'//测试环境
  const routerTag='https://m.rockstect.com/#/'//线上

module.exports = {
  formatMoney,
  GetUrlParams,
  isEmptyObj,
  timeDate,
  routerTag,
  // pathJump
}
