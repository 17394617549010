<template>
  <div class='i-p-header' v-if="payInfo!=null">
    <!-- <div class="i-p-page">
        <div class="i-p-back"></div>
        <div class="i-p-title">信息确认</div>
    </div> -->
    <!-- 还款详情 -->
    <div class="i-p-list">
        还款总金额(元)
    </div>
    <div class="i-p-money-title">
       {{payInfo.repayAmt}}
    </div>
    <div class="i-p-list-line-card">

         <div class="i-p-list-content">
            <div class="i-p-list-content-left">
                还款本金
            </div>
            <div class="i-p-list-content-right">
                ￥{{payInfo.repayBen}}
            </div>
        </div>

        <!-- 利息 -->

        <div class="i-p-list-content">
            <div class="i-p-list-content-left">
                利息
            </div>
            <div class="i-p-list-content-right">
                ￥{{payInfo.repayXi}}
            </div>
        </div>


        <div class="i-p-list-content">
            <div class="i-p-list-content-left">
                服务费
            </div>
            <div class="i-p-list-content-right">
                ￥{{payInfo.repayFu}}
            </div>
        </div>

         <div class="i-p-list-content">
            <div class="i-p-list-content-left">
               提前结清违约金
            </div>
            <div class="i-p-list-content-right">
                ￥{{payInfo.breakAmt}}
            </div>
        </div>
      
       <div class="i-p-list-content">
            <div class="i-p-list-content-left">
               已到账金额
            </div>
            <div class="i-p-list-content-right">
                ￥{{payInfo.backedAmt}}
            </div>
        </div>

         <div class="i-p-list-content">
            <div class="i-p-list-content-left">
               应退金额
            </div>
            <div class="i-p-list-content-right">
                ￥{{payInfo.refundAmt}}
            </div>
        </div>

    </div>

    <div class="i-p-footer-button">
        <div class="i-p-footer-button-text" @click="gopayll">确认还款</div>
    </div>

  </div>

</template>
<script>
/*
    settleAmt  应还总金额
    principleAmt  还款本金
    interestAmt  利息
    serviceAmt  服务费
    breakAmt  提前结清违约金
    backedAmt  已到账金额
    refundAmt  应退金额
    */
import { onMounted, reactive, toRefs } from 'vue'
import {Toast} from 'vant';
import APP from '../../utils/APP'
import API from '../../server/api'

export default {
  name: 'Index',
  setup () {
     
    // 设置标题Title
    APP.SET_TITLE('信息确认')

    const state = reactive({
        payInfo:null
    })

    // 初始化还款信息
    const initpayconfirm = async () => {
        const orderNo=window.localStorage.getItem('orderNo');
        const periods=JSON.parse(window.localStorage.getItem('periodNos'))
        const appNum=window.localStorage.getItem('appNo')
        const result = await API.payAll({
            appNo:appNum,
            orderNo:orderNo,
            payType:1,  //还款类型，0-正常还款（单，多期还款），1-提前结清
            periods:periods //期数列表 还款类型为0时，不能为空
        })
        state.payInfo=result.calc
        // console.log(JSON.stringify(state.payInfo.loanAmt),99);
 
    }
    //确认还款跳到支付页
    const gopayll=()=>{
         window.localStorage.setItem('payAmt',state.payInfo.repayAmt)
         window.localStorage.setItem('payType',1);//BYALL
        APP.JUMP_H5("http://supertest.jqtianxia.cn/#/Payrepayment",'支付')    
        
    }
   
    // 页面挂载完成
    onMounted(() => {
        initpayconfirm()
    })
    
    return {
      ...toRefs(state),
      gopayll
    }
  }
  
}
</script>
<style scoped lang='less' src='./index.less'></style>
