/* 客户端APP提供的接口封装 */
// import { Toast } from 'vant';
/**
 * Created by mmcai on 2015/11/30.
 * QQ:3248544136
 * Email:caimengmeng@docard.net
 * update:2015/11/30
 */

// const APP = window.APP;   back,backToHome,hideBackBtn
module.exports = {
  name: 'APP',
  IOS: navigator.userAgent.match(/Mac OS/),
  IPHONE: navigator.userAgent.indexOf('iPhone') > -1,
  ANDROID: navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Linux') > -1,
  BROWSER: {
    iPhone: navigator.userAgent.indexOf('iPhone') > -1,
    android: navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Linux') > -1,
    ios: navigator.userAgent.match(/Mac OS/), // ios
    width: window.innerWidth,
    height: window.innerHeight,
    isclient: navigator.userAgent.indexOf('ESD') > -1 || navigator.userAgent.indexOf('YYYQ') > -1 || navigator.userAgent.indexOf('SJJQ') > -1 || navigator.userAgent.indexOf('YPD') > -1
  },

  getLocParams: function (name) {
    const href = window.location.href
    const subIndex = href.indexOf('?')
    const paramsObj = {}
    if (subIndex !== -1) {
      const params = href.substr(subIndex + 1)
      const paramsMany = params.indexOf('&')
      if (paramsMany !== -1) {
        const paramsArr = params.split('&')
        paramsArr.forEach(function (item) {
          let val = item.split('=')[1]
          const i = val.indexOf('#')
          if (i !== -1) {
            val = val.substring(0, i)
          }
          paramsObj[item.split('=')[0]] = val
        })
      } else {
        let val = params.split('=')[1]

        const i = val.indexOf('#')
        if (i !== -1) {
          val = val.substring(0, i)
        }
        paramsObj[params.split('=')[0]] = val
      }
    }
    if (paramsObj.hasOwnProperty(name)) {
      return paramsObj[name]
    } else {
      return null
    }
  },
  // 获取设备信息
  DEVICE: function () {
    return new Promise(function (resolve, reject) {
      document.addEventListener('WinJSBridgeReady', function () {
        window.WinJSBridge.call('getDeviceInfo', function (res) {
          if (res) {
            resolve(res)
          } else {
            reject({ msg: '获取设备信息报错' })
          }
        })
      })
    })
  },
  //获取app的token    
  getUserToken: function (callback) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('appUserToken', function (resp) {
        callback && callback(resp)
      })
      
    })
  },
  // 隐藏 TitleBar
  HIDE_TITLE_BAR: function () {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('hideTitleBar')
    })
  },
  // 显示 TilteBar
  SHOW_TITLE_BAR: function () {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('showTitleBar')
    })
  },
  SET_TITLE: function (title) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('setTitle', title)
    })
  },
  JUMP: function (url, title) {
    if (this.BROWSER.isclient) {
      const isNativeUrl = /^onepin:\/\//.test(url)
      if (isNativeUrl) {
        url = url.substring(9)
        this.JUMP_NATIVE(url)
      } else {
        this.JUMP_H5(url, title)
      }
    } else {
      window.location.href = url
    }
  },
  JUMP_H5: function (url, title) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('jumpUrl', {
        url: url,
        title: title
      })
    })
  },
  // showDetentionView  挽留页
  show_View: function (content) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('showDetentionView', {
        content: content
      })
    })
  },
  //backToHome 返回首页
  GO_Index: function (url) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('backToHome',{ 
        url: url,
      })
    })
  },
   //pageMarkStatus 返回到首页
   Go_pageMark: function (pageStatus) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('pageMarkStatus', {
        pageStatus:pageStatus
      })
    })
  },
  setData: function (name,value) {
    //数据需要字符串类型
    // if(name!='string' && value !='string'){
    //   // Toast('传入的参数不是字符串类型')
    //   return false
    // }
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('storeMarkData',{ 
          key:name,
          value:value
      })
    })
  },
  //getMarkData取
  getData: function (name,callback) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('getMarkData', 
        {
          key:name
        },
        function (resp) {
          callback && callback(resp)
        })
      
    })
  },
  JUMP_NATIVE: function (url) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('jumpUrl', {
        // url: `onepin://${url}`,
        url:url,
        title: ''
      })
    })
  },
  //获取通讯录单个
  GET_CONTACT: function (callback) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('getContacts', function (resp) {
        callback && callback(resp)
      })
    })
  },
  //获取全部通讯录数据
  UPLOAD_DATA: function (callback) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('getAllContacts', function (resp) {
        callback && callback(resp)
      })
    })
  },
  // 打电话 callServiceClick
  Go_Phone: function (content) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('callServiceClick', {content:content})
      
    })
  },
  // num可回退几页的数值
  GO_BACK: function (num) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('back', num)
    })
  },
 
  PAGE_WILL_LOAD: function (callback) {
    document.addEventListener('WinJSBridgeReady', function (event) {
      window.WinJSBridge.call('onPageVisible', function (resp) {
        callback && callback(resp)
      })
    })
  },
  PAGE_SHOW: function (callback) {
    document.addEventListener('WinJSBridgeReady', function (event) {
      window.WinJSBridge.call('onPageShow', function (resp) {
        callback && callback(resp)
      })
    })
  },
  PAGE_HIDE: function (callback) {
    document.addEventListener('WinJSBridgeReady', function (event) {
      window.WinJSBridge.call('onPageHide', function (resp) {
        callback && callback(resp)
      })
    })
  },
  /* 主动刷新 */
  REFRESH: function () {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('reRresh')
    })
  },
  /* 下拉刷新 */
  SET_REFESH: function () {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('reFresh')
    })
  },
  IS_LOGIN: function (callback) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('isLogin', function (resp) {
        callback && callback(resp)
      })
    })
  },

  // 选项卡切换 0 = 首页  1 = 账单
  JUMP_TAB: function (num) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('tabClick', `${num}`)
    })
  }, 
  //隐藏显示返回按钮 true false
  IS_SHOW_BACK: function (flag) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('hideBackBtn', `${flag}`)
    })
  },
  // 隐藏键盘
  IS_HIDE_INPUT: function () {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('hideSoftInput')
    })
  },
  /* 弹框 */
  DIALOG_SHOW: function (title = '', content = '', callback) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('dialogAndroid', { title, content }, function (res) {
        callback && callback(res)
      })
    })
  },
  /*获取用户手机号 */
  mobile_info: function (callback) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('getMobileInfo', function (resp) {
        callback && callback(resp)
      })
      
    })
  },
  //获取appname 和appSourceId  放在获取设备信息里面
  // appName_info: function (callback) {
  //   document.addEventListener('WinJSBridgeReady', function () {
  //     window.WinJSBridge.call('getAppNameInit', function (resp) {
  //       callback && callback(resp)
  //     })
      
  //   })
  // },
  //调取摄像头实名 face++
  Camera_UP: function (token,ocrChanel,typeOCR,callback) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('getCamera',{token,ocrChanel,typeOCR}, function (resp) {
        callback && callback(resp)
      })
      
    })
  },
  //调取摄像头活体
  getFace_UP: function (faceToken,faceChanel,faceReqId,callback) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('getFace',{faceToken,faceChanel,faceReqId}, function (resp) {
        callback && callback(resp)
      })
      
    })
  },
  //新诺威尔 ocr
  XINNUO_Camera_UP: function (token,ocrChanel,typeOCR,ocrKe,ocrSecret,callback) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('getCameraXIN',{token,ocrChanel,typeOCR,ocrKe,ocrSecret}, function (resp) {
        callback && callback(resp)
      })
      
    })
  },
  //隐藏导航栏
  IS_HIDDEN: function () {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('hiddenNav')
    })
  },
  /* 接口请求 */
  Fetch: function (url, method, callback, params, env) {
    const that = this
    // 接口名字：webNetTool
    // 参数：paramsDict 数据参数
    // versionStr:'1.0.1', 版本号
    // urlStr:'app.index',
    // loanNo:'12341234',
    // 返回结果：responseObject
    const o = {
      url,
      method,
      callback,
      params
    }
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('webNetTool', {
        urlStr: url,
        urlHostStr: env,
        paramsDict: params,
        netTypeStr: method
      }, function (res) {
        const u = navigator.userAgent
        const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 //android终端或者uc浏览器
        if (isAndroid) {
          // alert(JSON.parse(res))
          res = JSON.parse(res)
        }
        callback && callback(res)
      })
    })
  },
  /* 弹框 */
  Alert: function (content) {
    document.addEventListener('WinJSBridgeReady', function () {
      window.WinJSBridge.call('webAlertinfo', content)
    })
  },
}
