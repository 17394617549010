/* 请求的接口封装 */

import request from "../utils/Ajax";
import APP from "../utils/APP";
// import request from '../utils/fetch'


export default {
  /*上传通讯录 */
  async addressBook(data) {
    return request.post("app/v1/fileReports", data, "portal");
  },
  /*获取osstoken */
  async OssToken(data) {
    const appName=window.localStorage.getItem('appName')
    return request.get(`app/v1/ossToken/${appName}`, data, "portal");
  },
  /*实名认证 */
  async authOcr(data) {
    return request.post("app/v1/ocr", data, "portal");
  },
  /*获取实名认证token */
  async authToken(data) {
    return request.post("app/v1/ocrToken", data, "portal");
  },
  /*实名认证提交 */
  async authSubmit(data) {
    return request.post("app/v1/realAuth", data, "portal");
  },
  /*活体获取token */
  async faceToken(data) {
    return request.get("app/v1/faceToken", data, "portal");
  },
  /*活体人脸识别 */
  async faceReco(data) {
    return request.post("app/v1/faceCheck", data, "portal");
  },
  /*基础信息初始化 */
  async baseInit(data) {
    return request.get("app/v1/infoInit", data, "portal");
  },
  /*基础信息提交 */
  async baseSubmit(data) {
    return request.post("app/v1/clientInfo", data, "portal");
  },
  /*首页登录 */
  async login(data) {  
    // console.log(data,'api')
    return request.post("app/v1/loginNoCheck", data, "portal");
  },
  /* 首页贷超  */

  async index(data) {
    return request.get("app/v1/default", data, "portal");
  },
  /* 贷款授信详情   */

  async indexDes(data) {  
    const idNO = window.localStorage.getItem("appNo");
  
    return request.get(`app/v1/loan/status/${idNO}`, data, "portal");
  },
  /*贷超产品详情*/
  async product(data) {
    const idNO = window.localStorage.getItem("appNo");
    return request.get(`app/v1/loanDetail/${idNO}`, data, "portal");
  },
  /*信息授权提交*/
  async productCredit(data) {
    return request.post("app/v1/credit", data, "portal");
  },
  /* 借款页面初始化*/
  async loanInits(data) {
    const orderNo = window.localStorage.getItem("orderNo");
    const idNO = window.localStorage.getItem("appNo");
    return request.get(
      `app/v1/loanDetail/${idNO}/${orderNo}/init`,
      data,
      "portal"
    );
  },
  /*提交借款*/
  async loanSubmit(data) {
    return request.post("app/v1/loan", data, "portal");
  },
  /*查询还款计划*/
  async loanFee(data) {
    return request.post("app/v1/loanPlan", data, "portal");
  },
  /*贷超列表*/
  async loanList(data) {
    return request.post("app/v1/loanList", data, "portal");
  },
  /*借款记录列表 */
  async loanRecord(data) {
    return request.post("app/v1/loanRecode", data, "portal");
  },
  /*待还账单列表*/
  async bill(data) {
    return request.get("app/v1/bills", data, "portal");
  },
  /*账单详情*/
  async billDetail(data) {
    const loanNo = window.localStorage.getItem("loanNo");
    return request.get(`app/v1/bills/${loanNo}`, data, "portal");
  },
  /*还款短信*/
  async paySmsCode(data) {
    return request.post("app/v1/repaySms", data, "portal");
  },
  /*还款*/
  async paySubmit(data) {
    return request.post("app/v1/repay", data, "portal");
  },
  /*提前还款试算(全部结清)*/
  async payAll(data) {
    return request.post("app/v1/calc", data, "portal");
  },
  /*获取贷后协议列表*/
  // async payProtol(data) {
  //   return request.get(`app/v1/loanProtocol/${loanNo}`, data,'portal')
  // },
  /*获取支持银行卡列表bankSupport*/
  async bankSupport(data) {
    const orderNo = window.localStorage.getItem("orderNo");
    const idNO = window.localStorage.getItem("appNo");
    return request.get(
      `app/v1/supportCards/${idNO}/${orderNo}`,
      data,
      "portal"
    );
  },
  /*银行卡信息初始化*/
  async bankInit(data) {
    return request.get("app/v1/user", data, "portal");
  },
  /*银行卡列表*/
  async bankLists(data) {
    const orderNo = window.localStorage.getItem("orderNo");
    const idNO = window.localStorage.getItem("appNo");
    return request.get(`app/v1/bankCards/${idNO}/${orderNo}`, data, "portal");
  },
  /*绑定银行卡发送验证码*/
  async bankSms(data) {
    return request.post("app/v1/bankSendCode", data, "portal");
  },
  /*绑定银行卡*/
  async bankBind(data) {
    return request.post("app/v1/saveCard", data, "portal");
  },
  /*意见反馈 投诉中心提交*/
  async feedback(data) {
    return request.post("user/v1/addFeed", data, "portal");
  },
  //意见反馈 投诉中心 上传oss
  async uploadImg(data) {
    return request.get("config/v1/oss", data, "portal");
  }
};
