import { createRouter, createWebHashHistory } from 'vue-router'
import Bill from '../views/Bill/index.vue'
import BillDetail from '../views/BillDetail/index.vue'
import PayConfirm from '../views/PayConfirm/index.vue'
import Index from '../views/Index/index'

const routes = [
  {
    path: '/',
    name: 'Index',
    desc: '惠有钱首页',
    component: Index
  },
  
  {
    path: '/bill',
    name: 'bill',
    desc: '账单首页',
    component: Bill
  },
  {
    path: '/billDetail',
    name: 'BillDetail',
    desc: '账单详情',
    component: BillDetail
  },
  {
    path: '/payConfirm',
    name: 'PayConfirm',
    desc: '支付确认',
    component: PayConfirm
  },
  

  {
    path: '/loan',
    name: 'Loan',
    desc: '借款',
    component: () => import('../views/Loan/index.vue')
  },
  {
    path: '/loanResult',
    name: 'loanResult',
    desc: '借款完成',
    component: () => import('../views/loanResult/index.vue')
  },
  {
    path: '/loanProtocol',
    name: 'LoanProtocol',
    desc: '借款协议',
    component: () => import('../views/LoanProtocol/index.vue')
  },
  {
    path: '/Payrepayment',
    name: 'Payrepayment',
    desc: '收银台',
    component: () => import('../views/Payrepayment/index.vue')
  },
  {
    path: '/payResult',
    name: 'payResult',
    desc: '支付成功',
    component: () => import('../views/payResult/index.vue')
  },
  {
    path: '/bankcard',
    name: 'bankcard',
    desc: '添加银行卡',
    component: () => import('../views/bankcard/index.vue')
  },
  {
    path: '/Product',
    name: 'Product',
    desc: '产品详情页',
    component: () => import('../views/Product/index.vue')
  },
  {
    path: '/audit',
    name: 'Audit',
    desc: '审核页',
    component: () => import('../views/audit/index.vue')
  },
  {
    path: '/approve',
    name: 'approve',
    desc: '认证完成',
    component: () => import('../views/approve/index.vue')
  },
  {
    path: '/Information',
    name: 'Information',
    desc: '补充信息',
    component: () => import('../views/Information/index.vue')
  },
  {
    path: '/announcement',
    name: 'announcement',
    desc: '系统公告',
    component: () => import('../views/announcement/index.vue')
  },
  {
    path: '/mechanism',
    name: 'mechanism',
    desc: '前往机构页面',
    component: () => import('../views/mechanism/index.vue')
  },
  {
    path: '/loanRecord',
    name: 'loanRecord',
    desc: '借款记录',
    component: () => import('../views/loanRecord/index.vue')
  },
  {
    path: '/Discovery',
    name: 'Discovery',
    desc: '发现页',
    component: () => import('../views/Discovery/index.vue')
  },
  {
    path: '/supportbank',
    name: 'supportbank',
    desc: '支持的银行卡',
    component: () => import('../views/supportbank/index.vue')
  },
  {
    path: '/feedback',
    name: 'FeedBack',
    desc: '意见反馈',
    component: () => import('../views/FeedBack/index.vue')
  },
  {
    path: '/complaint',
    name: 'Complaint',
    desc: '投诉中心',
    component: () => import('../views/Complaint/index.vue')
  },
  {
    path: '/auth',
    name: 'Auth',
    desc: '实名认证',
    component: () => import('../views/auth/index.vue')
  },
  {
    path: '/authFace',
    name: 'AuthFace',
    desc: '活体认证',
    component: () => import('../views/authFace/index.vue')
  },
  {
    path: '/baseInfo',
    name: 'BaseInfo',
    desc: '基础信息',
    component: () => import('../views/baseInfo/index.vue')
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
