<template>
  <div class='i-b-header'>
      <!-- <van-nav-bar
            title="账单详情"
            left-arrow
            @click-left="onClickLeft"
        /> -->
    <div class="i-b-page">
        <!-- <div class="i-b-back">
        </div> -->
        <div class="i-b-mask">
            <span class="i-b-title">账单详情</span>
        </div>
        <div class="i-b-notice">
            {{loanTime}}  借款金额￥{{loanMoeny}}，期限{{loanPeriod}}个月
        </div>
    </div>
    <div class="i-header-content">
      <!--卡片-->
        <div class="i-b-card" >
            <!-- 添加免责文案 -->
            <div class="i-b-card-texts" v-if='loanPro !=null'>
              该借款服务由{{loanPro.appName}}提供，本平台不承担任何责任
            </div>
            <div class="i-b-card-top">
              
                <div class="i-b-card-left">
                  {{currentPeriods.planDate}}第{{currentPeriods.planIndex}}期应还(元)
                </div>
            </div>
            <!-- 金额和提醒 -->
            <div class="i-b-card-middle">
                <div class="i-b-card-middle-left" v-if="currentPeriods.status!='SETTLED'">{{currentPeriods.repayAmt}}</div>
                <!-- 还款成功 -->
                <div class="i-b-card-middle-left" v-if="currentPeriods.status=='SETTLED'">本期还款成功</div>
            </div>
            <div class="i-b-card-bottom">
                <div class="i-b-card-bottom-left">
                协议编号：{{loanNum}}   
                <span class="i-b-card-bottom-right" @click="payAgreement">
                        查看协议
                    </span> 
                </div>
            </div>
        </div>
    </div>
    <!-- 账单 -->
    <div class="i-b-list-main">
      <div class="i-b-list-main-left">待还账单</div>
      <div class="i-b-list-main-right" v-if="loanPro!=null">
        <i class='i-b-icon'></i>
        <span class='i-b-icon-text' @click='goPhone'>联系客服</span>
      </div>
    </div>
    <div class="i-b-line-card">
        <div class='i-b-line-card-content' v-for='item in planList' :key="item.planIndex">
              <div class="i-b-line-card-new"  @click='checkStatus(item,item.chenckStatu)'>
                <!-- 选中状态 -->
                  <div v-if="item.statusStr=='OVERDUED' || item.currentPeriod=='YES'" >
                    <!-- <div class="i-b-line-card-imgs-acitve"  v-if='item.chenckStatu==false'  @click='checkStatus(item,item.chenckStatu)' ></div>
                   
                    <div class="i-b-line-cards-img" v-else ></div> -->
                     
                    <div :class="item.chenckStatu ? 'i-b-line-card-imgs-acitve':'i-b-line-cards-img' "></div>
                  </div>
                  <div v-if="item.statusStr!='OVERDUED' && item.currentPeriod!='YES'">
                        <div class="i-b-line-cards-img" ></div>
                  </div>
                  <!-- 还款的金额 -->
                  <div class="i-b-line-card-left">
                    <div class='i-b-line-card-number'>{{item.planAmt}}</div>
                    <div class='i-b-line-card-time'>还款日：{{item.planDate}}</div>
                  </div>
                  <!-- 还款计划状态 0-还款中，1-已结清，2-已逾期 -->
                  <div class='i-b-line-card-right' > 
                      <div class='i-b-line-card-plan'>第{{item.planIndex}}期</div>
                        <div class='i-b-line-card-status' v-if='item.currentPeriod=="YES"'>
                          状态：<span class='i-b-line-card-status-text-f'>待还款</span>
                        </div>
                        <div class='i-b-line-card-status' v-if='item.statusStr=="OVERDUED" && item.currentPeriod=="NO"'>
                          状态：
                          <span class='i-b-line-card-status-text-t'>已逾期</span>
                        </div>
                        <div class='i-b-line-card-status' v-if='item.statusStr!="OVERDUED" && item.currentPeriod=="NO"'> 
                          状态： <span class='i-b-line-card-status-text-s' >未到期</span>
                        </div>
                        
                  </div>
              </div>
            
        </div>
        <div class="i-b-line-clear" @click="payAll">提前结清</div>
    </div>

    <div class="i-b-footer-button" @click="paysubmit">
        <div class="i-b-footer-button-text" >立即还款</div>
    </div>

  </div>

</template>
<script>
import { onMounted, reactive, toRefs } from 'vue'
import { GetUrlParams, isEmptyObj,routerTag} from '../../utils/index'
import Moment from 'moment'; 
import {Toast} from 'vant';
import APP from '../../utils/APP'
import API from '../../server/api'
import {create, all,} from 'mathjs';
// 首页额度
import BankList from '@/components/BankList/index.vue'
import Modal from '@/components/Modal/Modal.vue'


const config = ({
        number: 'BigNumber',
        precision: 20
        });
const math = create(all, config);
export default {
  name: 'Index',
  components: {
    BankList,
    Modal
  },
  setup () {
     
    // 设置标题Title
    // APP.SET_TITLE('账单详情')

    const state = reactive({
      clientNo:'',//客户编号
      loanNo:'',//借款编号
      planList:[],//还款列表
      planTime:'',//还款日期
      loanTime:'',//放款日期
      loanMoeny:'',//借款总金额
      loanPeriod:'',//借款期限
      currentPeriods:'',//当前期
    
      //  chenckStatu:false,//选中的壮态
      //  chenckStatu:false,
      payArray: [],
      payAmt:0,
      AllArray:[],
      Moment,
      loanNum:'',//协议编号
      loanPro:null,
      // payInfo:null,//全部结清
    })

    // 初始化还款信息
    const initpay = async () => {
      // const params = GetUrlParams()
      // if (!isEmptyObj(params)) {
        // APP.Alert(JSON.stringify(params),8844)
        
        const result = await API.billDetail({ })
        state.planList=result.repayList
        state.loanPeriod=result.repayDetail.loanTerm
        state.loanMoeny=result.repayDetail.loanAmount
        state.loanTime=result.repayDetail.raiseTimeStr
        state.currentPeriods=state.planList[0]
        state.loanNum=result.repayDetail.loanNo
        state.loanPro=result.product  
        window.localStorage.setItem('protocol',JSON.stringify(result.protocol))
        window.localStorage.setItem('orderNo',result.repayDetail.orderNo)
        window.localStorage.setItem('appNo',result.repayDetail.appNo)
        if(state.planList!=null){
            state.planList.map((r)=>{
              r.chenckStatu=false  
              
            })
        }
       
     
    }
   //获取还款协议
    const payAgreement=async()=>{
       const protoUrl=routerTag+"LoanProtocol"
        APP.JUMP_H5(protoUrl,'协议')
     
    }
     //单期还款选中按钮
    const checkStatus = (item,flag) => {
      
      console.log(JSON.stringify(item),flag);
      const {planIndex}=item
  
       if(item.statusStr=='OVERDUED' || item.currentPeriod=='YES'){
           if (!flag) {
            state.planList.forEach((r) => {
               
                if (r.statusStr !== 'SETTLED' && r.planIndex <= planIndex) {
                   
                    r.chenckStatu = state.chenckStatu=true;
                  
                    const index = state.payArray.indexOf(r.planIndex);

                    if (index === -1) {
                        state.payArray.push(r.planIndex);
                        state.payAmt = math.number(math.add(math.bignumber(state.payAmt), math.bignumber(r.repayAmt * 1)));
                        
                        console.log(state.payAmt,9999);
                        window.localStorage.setItem('payAmt',state.payAmt);
                    
                        window.localStorage.setItem('periodNos',JSON.stringify(state.payArray));
                    }
                }
            });

        } else {
        state.planList.forEach((r) => {
              
            if (r.planIndex >= planIndex && r.chenckStatu) {
              
                r.chenckStatu = state.chenckStatu=false;
             
                const index = state.payArray.indexOf(r.planIndex);
                
                if (index !== -1) {
                    state.payArray.splice(index, 1);  
                   
                }
                state.payAmt = math.number(math.subtract(math.bignumber(state.payAmt), math.bignumber(r.repayAmt * 1)));
                console.log(state.payAmt,865);
                window.localStorage.setItem('payAmt',state.payAmt);
                window.localStorage.setItem('periodNos',JSON.stringify(state.payArray));
            }
        });
      }
       }
     
      
    }
    // 初始化全部结清信息
    const initpayconfirm = async () => {
        const orderNo=window.localStorage.getItem('orderNo');
        const periods=JSON.parse(window.localStorage.getItem('periodNos'))
        const appNum=window.localStorage.getItem('appNo')
        const result = await API.payAll({
            appNo:appNum,
            orderNo:orderNo,
            payType:1,  //还款类型，0-正常还款（单，多期还款），1-提前结清
            periods:periods //期数列表 还款类型为0时，不能为空
        })
        // state.payInfo=result.calc
      //  console.log(result,'866')
      //  return false
        if(result.calc.repayAmt>0){
          // window.localStorage.setItem('payAmt',result.calc.repayAmt)
          // window.localStorage.setItem('payType',1);//BYALL
          // const urlPay=routerTag+"Payrepayment"
          const urlPay = routerTag + "Payrepayment"+'?'+'paytype'+'='+'1';
          APP.JUMP_H5(urlPay,'支付') 
        }else{
          Toast('首期不支持提前结清')
          return false
        }
    }
    //全部结清
   const payAll=()=>{
        // console.log(66);
         window.TDAPP.onEvent("账单详情页-点击全部结清-进入");
        if(state.planList!=null){
          if(state.AllArray.length>0){
            state.AllArray=[]
          }
          state.planList.map((r)=>{  
                state.AllArray.push(r.planIndex)
                console.log(state.AllArray,999);
                window.localStorage.setItem('periodNos',JSON.stringify(state.AllArray));    
                
          }) 
          initpayconfirm()  
        }  
        
        
       
       
   }
   //立即还款
    const paysubmit=()=>{
       window.TDAPP.onEvent("账单详情页-点击立即还款-进入");
        if(state.payAmt==0 || state.payAmt==NaN){
            Toast('请选择还款期数')
            return false
        }
        // console.log(state.payAmt,999);
        // window.localStorage.setItem('payType',0);//BYALL
        const singleUrl = routerTag + "Payrepayment"+'?'+'paytype'+'='+'0';
        // const singleUrl=routerTag+"Payrepayment"
        APP.JUMP_H5(singleUrl,'支付') 

    }
    const goPhone=()=>{

       APP.Go_Phone(state.loanPro.customerNumbers)
    }
   
    // 页面挂载完成
    onMounted(() => {
      initpay();
      APP.SET_TITLE('亲和贷')
      // console.log(7777)
      APP.PAGE_WILL_LOAD(()=>{
         window.localStorage.setItem('payAmt','')
          console.log(5555)
          APP.REFRESH()
      })

    })
      
    return {
      ...toRefs(state),
      payAgreement,
      payAll,
      checkStatus,
      paysubmit,
      goPhone,
      // onClickLeft
    }
  }
  
}
</script>

<style scoped lang='less' src='./index.less'></style>
