import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/normalize.css'
import './assets/layout.less'
import Vant from 'vant';
import 'vant/lib/index.css';

const app = createApp(App)
app.use(store).use(router).use(Vant).mount('#app')

console.log(app.config, 'VUE3 的配置')

// 自定义全局组件
app.component('button-mc', {
  data: () => ({
    count: 0
  }),
  template: '<button>按钮</button>'
})

// 定义全局指令
app.directive('focus', {
  mounted: el => el.focus()
})

// provide 为所有的子组件，注入全局内容

// 全局属性
app.config.globalProperties.$filters = {
  formatMoney (s, n) {
    if (!s) s = 0
    n = n > 0 && n <= 20 ? n : 2
    s = parseFloat((s + '').replace(/[^\d.-]/g, '')).toFixed(n) + ''
    const l = s.split('.')[0].split('').reverse()
    const r = s.split('.')[1]
    let t = ''
    for (let i = 0; i < l.length; i++) {
      t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? ',' : '')
    }
    return t.split('').reverse().join('') + '.' + r
  }
}
