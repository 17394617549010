<template>
  <div class="com-quota-card">
    <div class="com-q-title">最高可借额度(元)</div>
    <div class="com-q-content">
      <div class="com-q-money">
        {{ $filters.formatMoney(data.availableAmt, 2) }}
      </div>
      <!-- :class="{'com-q-btn-over':data.button.clickable===0}" -->
      <div @click="btnHandler" class="com-q-btn" >
        {{ data.button.label }}
      </div>
     
    </div>
    <div class='com-a-text'>注：放款资金由第三方提供，最终放款情况以资方放款额度为准</div>
  </div>
</template>

<script>
import APP from '../../utils/APP'
import { onMounted, reactive, toRefs, watch } from 'vue'
import { routerTag} from '../../utils/index'
import API from '../../server/api.js'

export default {
  name: 'QuotaCard',
  props: {
    data: Object,
    step: Number
  },
 
  setup (props) {
    const state = reactive({
      name: ''
    })
    const CreditDes= async()=>{
      window.TDAPP.onEvent("首页卡片点击-进入");
      const result=await API.indexDes({})
        window.localStorage.setItem('orderNo',result.creditResult.orderNo)
        if(result.creditResult.creditStatus==4){
           const loanUrl=routerTag+"loan"
            APP.JUMP_H5(loanUrl,'借款') 
         
        }else if(result.creditResult.creditStatus==7 ){
          window.localStorage.setItem('loanNo',result.creditResult.loanNo)
           const BillUrl=routerTag+"BillDetail"
            APP.JUMP_H5(BillUrl,'账单详情') 
       
          
        }else{
           initCard()
        }
    }
    const initCard=()=>{
      let { clickable, url} = props.data.button;
      const step = props.step;
      console.log(step,886)
          if(step==0){
            // console.log(step,55545)
            const authUrl=routerTag+"auth"
            APP.JUMP_H5(authUrl,'实名认证') 
          }else if(step==1){
             const faceUrl=routerTag+"authFace"
            APP.JUMP_H5(faceUrl,'人脸识别') 
          }else if(step==2){
            const baseUrl=routerTag+"baseInfo"
            APP.JUMP_H5(baseUrl,'基础信息') 
          }else{
            // console.log(url,9766)
            // return false
            APP.JUMP_H5(url)
          }
    }
    const btnHandler = async() => {
      // window.localStorage.setItem('appN','33435')
      window.localStorage.setItem('appNo',props.data.appNo)
      //存数据
      APP.setData('appNo',props.data.appNo)
    
      await CreditDes()
    }
    return {
      ...props,
      ...toRefs(state),
      btnHandler
    }
  },
 
}
</script>

<style lang="less" src="./index.less" scoped></style>
